import { App, Input, Modal, notification, Select, Typography } from "antd"
import React, { useState } from "react"
import { User } from "../../../data/models/user";
import { makeRequest } from "../../helpers/axios-manipulator";
const { Text } = Typography;

type Props = {
    open: boolean,
    setOpen: Function
    setUserSelected: Function;
    setParentLoading: Function;
}

const NewUserModal = ({ open, setOpen, setUserSelected, setParentLoading }: Props) => {
    const [user, setUser] = useState<User>({ type: 1 } as User)
    const [loading, setLoading] = useState<boolean>(false);
    const [usernameStatus, setUsernameStatus] = useState<"" | "error">("");
    const { message } = App.useApp();
    const [api, contextHolder] = notification.useNotification();
    const userTypes = [
        { label: "Artist", value: "1" },
        { label: "Collector", value: "2" },
        { label: "3 - Manager", value: "3" },
        { label: "4 - Dealer", value: "4" },
    ]

    const checkUsername = async () => {
        let userfound;
        try {
            const response = await makeRequest({
                origin: window.location.origin,
                method: 'get',
                url: `/api-user/verify-profile/${user.username}`,
                headers: {},
            });
            userfound = response
        } catch (error) {
            console.error('Error fetching user profilename:', error);
        }
        return userfound
    };

    const createUser = async () => {
        const newUser = {
            ...user, isEnabled: "Y", profileName: user.username, publicProfile: [true],
            isArtist: 0,
            isCollector: 0,
            artistProfile: {
                username: user.username,
                displayName: user.display_name,
            },
            collectorProfile: {
                username: user.username,
                displayName: user.display_name,
            }
        }
        const hasUser = await checkUsername();
        if (hasUser) {
            setUsernameStatus("error")
            return api['error']({
                duration: 5,
                message: 'Username in use',
                description:
                    'This username is already in use.',
            });
        }
        setLoading(true)
        try {
            console.log("dentro do segundo try")
            const response = await makeRequest({
                origin: window.location.origin,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                url: '/api-users/create',
                data: newUser,
            });
            setUserSelected(response.user.username)
            setParentLoading(true)
            setParentLoading(false)
            const onboardingResp = await makeRequest({
                origin: window.location.origin,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                url: '/api-onboarding/create',
                data: {
                    userid: response.user.id,
                    username: response.user.username,
                    notification_status: "OPEN",
                    account_type: response.user.type,
                    notification_email: response.user.email,
                    isActive: true,
                }
            });
            setLoading(false)
            setOpen(false)
            setUser({} as User)
            message.success('User created with success!');
        } catch (error) {
            setLoading(false)
            message.error('Something went wrong during the creation.');
        }
    }

    const enableSubmit = () => {
        if (!user.type || !user.username || !user.display_name || !user.email)
            return true
        return false
    }

    return (
        <Modal
            centered
            closable={false}
            maskClosable={false}
            title="Create new user"
            open={open}
            okText="Create"
            onOk={createUser}
            onCancel={() => { setOpen(false) }}
            width={500}
            okButtonProps={{ disabled: enableSubmit(), loading: loading }}
        >
            {contextHolder}
            <Text><span style={{ color: "red" }}>*</span> User Type </Text>
            <Select
                placeholder="Select user type"
                style={{ width: '100%' }}
                className="mb-2"
                defaultValue={"1"}
                onChange={(e) => setUser({ ...user, type: parseInt(e) })}
                options={userTypes}
            />

            <Text><span style={{ color: "red" }}>*</span> Username </Text>
            <Input
                status={usernameStatus}
                className="mb-2"
                count={{
                    show: true,
                    max: 20,
                }}
                value={user.username || ""}
                onChange={(e) => {
                    let newValue = (e.target as HTMLInputElement).value.toLowerCase();

                    newValue = newValue.replace(/[^a-z0-9-_]/g, "");
                    if (20 !== undefined) {
                        if (newValue.length > 20) {
                            newValue = newValue.slice(0, 20);
                        }
                    }
                    setUsernameStatus("")
                    setUser({ ...user, username: newValue })
                }}
                placeholder="Username" />

            <Text><span style={{ color: "red" }}>*</span> Display name </Text>
            <Input
                className="mb-2"
                onChange={(e) => setUser({ ...user, display_name: e.target.value })}
                placeholder="Display name" />
            <Text> <span style={{ color: "red" }}>*</span> Email</Text>
            <Input
                className="mb-2"
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                placeholder="Email" />
        </Modal>
    )
}

export default NewUserModal;