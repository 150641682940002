import { ReactNode, useEffect, useState } from "react";
import { User } from "../../data/models/user";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmptyObject } from "../helpers/empty-objects-validator";
import { Avatar, Breadcrumb, BreadcrumbItemProps, Button, Drawer, Layout, Spin, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Footer, Header } from "antd/es/layout/layout";
import PrivateMenu from "../components/menu/PrivateMenu";
import SearchInput from "../components/menu/Form/input/SearchInput";
import { LoadingOutlined, LogoutOutlined, MenuUnfoldOutlined, UserOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/appState";
import logo from "../assets/logos/logo.svg";

export { };

type props = {
  user: User,
  children: ReactNode,
}

interface BreadcrumbItem {
  title: string;
}

const handleResetPage = () => {
  Cookies.remove('user');
  window.location.href = window.location.href;
};

const PrivateLayout = ({ user, children }: props) => {
  const { loading } = useSelector((state: RootState) => state.loading);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const urlParams: string[] = location.pathname.split('/');
  const isSmallScreen = window.innerWidth < 500;
  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();
  const navigate = useNavigate()
  useEffect(() => {
    if (isEmptyObject(user)) navigate('/login')
  })
  const modifyBreadcrumb = (): BreadcrumbItem[] => {
    let newArray: BreadcrumbItem[] = [];

    urlParams.forEach((param, i) => {
      if (param === '') return;
      newArray.push({ title: param });
    });

    return newArray;
  };
  const toggleCollapsed = () => {
    setCollapsed(true)
  }
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {
        isSmallScreen ?
          <>
            <Button type="primary" onClick={toggleCollapsed} style={{ position: 'absolute', zIndex: 1, top: '72px', left: '10px' }}>
              <MenuUnfoldOutlined />
            </Button>
            <Drawer
              width="250"
              placement='left'
              closable={false}
              onClose={() => setCollapsed(false)}
              open={collapsed}
              styles={{ body: { paddingLeft: 0, paddingRight: 0 } }}
              style={{ background: colorPrimary }}
            >
              <Sider width={250} style={{ background: colorBgContainer }}>
                <div style={{ background: colorPrimary, height: '46px', padding: '1.5rem', cursor: 'pointer' }} onClick={() => navigate('/')}>
                  <div style={{ background: '#ffffff82', width: '100%', height: '100%', borderRadius: '8px' }}></div>
                </div>
                <PrivateMenu />
              </Sider>
            </Drawer>
          </>
          :
          <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} style={{ position: 'sticky', top: 0, height: '100vh' }}>
            <div style={{ margin: '17px auto 17px auto', borderRadius: '5px', height: '46px', padding: '7px', width: '75%', cursor: 'pointer' }} onClick={() => navigate('/')}>
            <img style={{ maxWidth: '330.4px', maxHeight: '84px'}} src={logo}  alt={'Ujamaa logo'} />
            {/* <Avatar style={{background: colorPrimary }} icon={<UserOutlined />} /> */}
            </div>
            <PrivateMenu />
          </Sider>
      }
      <Layout style={{ position: 'relative'}}>
        {loading && <div className="layout-overlay"><Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} /></div>}
        <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', background: colorBgContainer, padding: '5px' }}>
          <SearchInput />
          <Button onClick={() => (handleResetPage())} style={{ marginLeft: '1rem' }} type="primary" icon={<LogoutOutlined />} />
        </Header>
        <div style={{borderRadius: '2px', boxShadow: '1px 1px 5px #555 inset', padding: '0.5rem 1rem 2.5rem 1rem',}}>
        <Content style={{ margin: '0 16px'}}>
          {/*<Breadcrumb style={{ margin: `${isSmallScreen ? '2.8rem 0 1rem 0' : '16px 0'}` }} items={modifyBreadcrumb()}>
          </Breadcrumb>*/}
          {children}
        </Content>
        </div>
        <Footer style={{ textAlign: 'center' }}>
          ©{new Date().getFullYear()} Created by Ujamaa
        </Footer>
      </Layout>
    </Layout>
  )
}

export default PrivateLayout