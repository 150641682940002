import React from 'react';
import './style.css';  

const LabelValue = ({ label, value = null, secondaryValue = null, position = 'top', size = 'default' }) => {
  return (
    <div className="label-value-container">
      {position === 'top' && (
        <>
          {label && <div className={`label ${size}`}>{label}</div>}
          <div className={`value ${size}`}>{value}</div>
          {secondaryValue && <div className={`secondary-value ${size}`}>{secondaryValue}</div>}
        </>
      )}
      {position === 'bottom' && (
        <>
          {secondaryValue && <div className={`secondary-value ${size}`}>{secondaryValue}</div>}
          <div className={`value ${size}`}>{value}</div>
          {label && <div className={`label ${size}`}>{label}</div>}
        </>
      )}
    </div>
  );
};

export default LabelValue;
