import React, { useState, useEffect/*, useMemo */ } from 'react'; 
import { UploadOutlined, PictureOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Checkbox, Col, Row, Button, Skeleton, Select, Table, Upload, Image, Typography, Tag, Divider, Spin, Input } from 'antd';
import { useSelector } from 'react-redux';
import { setLoading } from '../../../redux/loadingSlicer';
import { RootState } from '../../../redux/appState';
import { makeRequest } from '../../helpers/axios-manipulator';
import Resizer from "react-image-file-resizer";
import Papa from "papaparse";
import * as XLSX from "xlsx";

const { Title, Text } = Typography;

const { Option } = Select;

const defaultImageUrl = "https://app.ujamaa.digital/images/defaultImage.svg";

const InventoryManagement = () => {
  const [data, setData] = useState<any>({});
  const user = useSelector((state: RootState) => state.user.user);
  
  const [imageList, setImageList] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<{ name: string; url: string }[]>([]);
  const [templateData, setTemplateData] = useState<any[]>([]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isTemplateUploaded, setIsTemplateUploaded] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]); // State for selected row keys
  const [newArtist, setNewArtist] = useState<string | null>(null); // State for updating artists
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false); // To track if all rows are selected
  const [isIndeterminate, setIsIndeterminate] = useState(false); // For indeterminate state of Select All checkbox
  const [rowStatuses, setRowStatuses] = useState<Record<string, 'pending' | 'success' | 'error'>>({});
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const allUploadsDone = Object.keys(rowStatuses).length > 0 && Object.values(rowStatuses).every(status => status === "success");
  const successCount = Object.values(rowStatuses).filter(status => status === "success").length;
  const [selectionInput, setSelectionInput] = useState<string>("")

  // Check if all rows have a selected artist
  const checkIfAllSelected = () => {
    const allSelected = templateData.length > 0 &&
                        templateData.every((row) => row.selectedArtist && row.selectedArtist !== "");
    
    setIsSaveEnabled(allSelected);
  };

  // Check if all rows have a selected artist whenever data changes
  useEffect(() => {
    checkIfAllSelected();
  }, [templateData]);

  const handleUpdateSelectedArtists = () => {
    if (!newArtist) return; // Ensure an artist is selected before updating
  
    setTemplateData((prevData) =>
      prevData.map((row) =>
        selectedRows.includes(String(row.key)) // Convert key to string for comparison
          ? { ...row, selectedArtist: newArtist }
          : row
      )
    );
    setSelectedRows([]); // Clear selection after updating
  }

  const handleFolderSelect = async (info: any) => {
    const files: File[] = info.fileList.map((file: any) => file.originFileObj);
    
    // Filter only supported image formats (JPEG, PNG, HEIC, HEIF)
    const supportedFiles = files.filter(file => 
      ["image/jpeg", "image/png", "image/heic", "image/heif"].includes(file.type)
    );

    setImageList(supportedFiles);
    
    // Resize images for preview
    const resizedImages = await Promise.all(
      supportedFiles.map(file =>
        new Promise<{ name: string/*; uid: string*/; url: string }>((resolve) => {
          let outputFormat = "JPEG"; // Default format
          
          if (file.type === "image/png") outputFormat = "PNG"; 
          // HEIC/HEIF might need external conversion before resizing

          Resizer.imageFileResizer(
            file,
            150, // Width
            150, // Height
            outputFormat, // Dynamic format based on file type
            50, // Quality
            0,
            (uri) => resolve({ name: file.name/*, uid: file.uid*/, url: uri as string }),
            "base64"
          );
        })
      )
    );

    setPreviewImages(resizedImages);
    setIsImageUploaded(true);
  };

  const handleSave = async () => {
    setLoading(true);
    setShowStatusColumn(true); // Show the status column
    let failedUploads: any[] = [];
    
    const updatedTemplateData = await Promise.all(
      templateData.map(async (row, index) => {
        if (row.selectedImage) {
          let formData = new FormData();

          formData.append('rootUser', String(isRootUser()));
          formData.append('userId', user.id);

          // Find the file object from imageList that matches selectedImageFilename
          const selectedImage = imageList.find(img => img.name === row.selectedImageFilename);

          // Check if the selected image is found
          if (selectedImage) {
            // Append the matching file object to formData
            formData.append('file', selectedImage);
          } else {
            console.error(`Image with filename ${row.selectedImageFilename} not found in imageList.`);
          }

          let attempt = 0;
          let success = false;
          let imageUrl = '';

          // Retry up to 3 times if upload fails
          while (attempt < 3 && !success) {
            try {
              const response = await makeRequest({
                origin: window.location.origin,
                method: 'post',
                url: '/uploadImage',
                headers: {},
                data: formData, // This will automatically send as 'multipart/form-data',
              });
              
              setLoading(false);
              if (!response.ok) throw new Error('Upload failed');
  
              imageUrl = response.url;
              success = true;
              setRowStatuses(prev => ({ ...prev, [row.key]: 'success' }));
            } catch (error) {
              console.error(`Upload failed for ${row.selectedImageFilename}, attempt ${attempt + 1}`, error);
              attempt++;
            }
          }
  
          if (!success) {
            failedUploads.push(row);
            console.log("Error: Failed onboarding.........", failedUploads)
            setRowStatuses(prev => ({ ...prev, [row.key]: "error" }));
            return row;
          }
  
          return { ...row, imageUrl: imageUrl };
        }
        return { ...row, imageUrl: defaultImageUrl };
      })
    );
  
    setTemplateData(updatedTemplateData);
  
    // Notify if any uploads failed
    if (failedUploads.length > 0) {
      alert(`Some images failed to upload. Please try again.`);
      setLoading(false);
      return;
    }
  
    // Onboard each artwork into the DB
    updatedTemplateData.forEach(async (row) => {
      await onboardArtwork(row, row.key);
    });
  
    setLoading(false);
  };

  const handleTemplateUpload = (file: File) => {
    setShowStatusColumn(false); // Show the status column
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      if (!data) return;

      if (file.name.endsWith(".csv")) {
        const parsed = Papa.parse<any>(data as string, {
          header: true,
          skipEmptyLines: true,
        });
        setTemplateData(parsed.data.map((row, index) => ({ key: index, ...row, selectedImage: null })));
      } else if (file.name.endsWith(".xlsx")) {
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsed = XLSX.utils.sheet_to_json<any>(sheet);
        setTemplateData(parsed.map((row, index) => ({ key: index, ...row, selectedImage: null })));
      }
      
      setIsTemplateUploaded(true);
    };
    reader.readAsBinaryString(file);
    return false;
  };

  const handleImageSelect = (value: string, key: string) => {
    // Find the selected image object by matching the URL (value) from the list
    const selectedImage = previewImages.find((img) => img.url === value);
  
    if (selectedImage) {
      // Update both selectedImage and selectedImageUrl
      setTemplateData((prevData) =>
        prevData.map((row) =>
          row.key === key
            ? {
                ...row,
                selectedImageFilename: selectedImage.name, 
                selectedImage: selectedImage.url,
              }
            : row
        )
      );
    }
  };

  const handleArtistSelect = (value: string, recordKey: number) => {
    setTemplateData((prevData) =>
      prevData.map((row) => (row.key === recordKey ? { ...row, selectedArtist: value } : row))
    );
  };

  const handleSelectRow = (checked: boolean, recordKey: string) => {
    setSelectedRows((prevSelected) =>
      checked
        ? [...prevSelected, recordKey] // Add to selection
        : prevSelected.filter((key) => key !== recordKey) // Remove from selection
    );
  };

  const handleSelectAll = (e: any) => {
    const checked = e.target.checked;
    // Check if templateData is empty
    if (templateData.length === 0) {
      setSelectedRows([]); // No rows to select
      setIsAllSelected(false); // Ensure 'Select All' checkbox is unchecked
      setIsIndeterminate(false); // Reset indeterminate state
      return;
    }

    if (checked) {
      setSelectedRows(templateData.map((row) => row.key.toString())); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all rows
    }

    setIsAllSelected(checked);
    setIsIndeterminate(false); // Reset indeterminate state
  };

  useEffect(() => {
    if (templateData.length && selectedRows.length === templateData.length) {
      setIsAllSelected(true);
      setIsIndeterminate(false); // All selected
    } else if (selectedRows.length === 0) {
      setIsAllSelected(false);
      setIsIndeterminate(false); // None selected
    } else {
      setIsIndeterminate(true); // Some selected
    }
  }, [selectedRows]);

  // Parses user input (e.g., "1,2,3-10") into an array of selected row keys
  const handleSelectionInput = (value: string) => {
    setSelectionInput(value);
  
    const selectedKeys = new Set<string>();
  
    value.split(",").forEach((part) => {
      if (part.includes("-")) {
        // Handle ranges like "3-7"
        const [start, end] = part.split("-").map(Number);
        if (!isNaN(start) && !isNaN(end) && start <= end) {
          for (let i = start; i <= end; i++) {
            selectedKeys.add((i-1).toString()); // Convert numbers to strings
          }
        }
      } else {
        // Handle individual values like "2"
        const num = Number(part.trim());
        if (!isNaN(num)) {
          selectedKeys.add((num-1).toString());
        }
      }
    });
  
    setSelectedRows(Array.from(selectedKeys)); // No more type errors
  }

  const columns = [
    ...(!showStatusColumn ? [
    {
      title: (
        <>
          <Checkbox
          checked={isAllSelected}
          indeterminate={isIndeterminate}
          onChange={handleSelectAll}
        />
        <Input
          placeholder="Enter range (e.g. 1-5,7,9-12)"
          value={selectionInput}
          onChange={(e) => handleSelectionInput(e.target.value)}
          style={{ width: 200, marginLeft: 8 }}
        />
        </>
      ),
      key: "selectAll",
      render: (_: any, record: any) => (
        <Checkbox
          checked={selectedRows.includes(record.key.toString())} // Ensure consistency
          onChange={(e) => handleSelectRow(e.target.checked, record.key.toString())}
        />
      ),
    },
    {
      title: "Index",
      key: "index",
      render: (_: any, record: any, index: number) => {
        return record.key + 1;; // Calculate the global index
      },
    },] : [
    {
      title: "Status",
      key: "status",
      render: (_: any, record: any) => {
        const status = rowStatuses[record.key];
        if (status === "success") return <Tag color="green">✅ Success</Tag>;
        if (status === "error") return <Tag color="red">❌ Error</Tag>;
        if (status === "pending" || status === undefined) return <><Tag color="blue">⏳ Uploading</Tag><Spin size="small" /></>;

        return null;
      },
    },
   ]),
    {
      title: "Artist",
      dataIndex: "selectedArtist",  // Used for render, this doesn't need to exist in initial data
      key: "artist",
      render: (_: any, record: any) => (
        <Select
          style={{ width: 200 }}
          onChange={(value) => handleArtistSelect(value, record.key)}  // Handle selection
          value={record.selectedArtist || undefined}  // Display selected artist
          allowClear
          placeholder={<div><UserOutlined /> <span>Select an artist</span></div>}
          disabled={rowStatuses[record.key] === "success"}  // Disable if successful
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.title.toLowerCase().includes(input.toLowerCase())
          } // Case-insensitive search
        >
          {data?.artists?.rows
            ?.slice() // Create a shallow copy to avoid mutating the original array
            ?.sort((a, b) => a.display_name.localeCompare(b.display_name)) // Sort alphabetically
            ?.map((artist, index) => (
            <Option key={index} value={artist.username} title={artist.username}>
              {artist.display_name}  {/* Assuming the artist has a 'username' property */}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Artwork",
      key: "image",
      height: "50px",
      render: (_: any, record: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Select
            style={{ width: "300px", height: "60px" }}
            onChange={(value) => handleImageSelect(value, record.key)}
            value={record.selectedImage || undefined}
            allowClear
            placeholder={<div><PictureOutlined /> <span>Select an artwork</span></div>}
            disabled={rowStatuses[record.key] === "success"}  // Disable if successful
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.title.toLowerCase().includes(input.toLowerCase())
            } // Case-insensitive search
          >
            {previewImages
              ?.slice() // Create a shallow copy to avoid mutating the original array
              ?.sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
              ?.map((img) => (
              <Option key={img.name} value={img.url} title={img.name}>
                <div 
                  style={{
                    display: "flex", 
                    alignItems: "center", 
                    gap: "10px", 
                    height: "50px", // Set the height for proper alignment
                    overflow: "hidden",
                    maxWidth: "300px" // Adjust the maxWidth as necessary
                  }}
                >
                  <Image src={img.url} height={50} preview={false} />
                  <span style={{ whiteSpace: "normal", wordWrap: "break-word", maxWidth: "200px" }}>
                    {img.name}
                  </span>
                </div>
              </Option>
            ))}
          </Select>
          {/*record.selectedImage && <Image height={50} src={record.selectedImage} />*/}
        </div>
      ),
    },
    { title: "Title", dataIndex: "Title", key: "title" },
    ...(!showStatusColumn
      ? [{ title: "Description", dataIndex: "Description", key: "description" },
    { title: "Length", dataIndex: "Length", key: "length" },
    { title: "Width", dataIndex: "Width", key: "width" },
    { title: "Depth", dataIndex: "Depth", key: "depth" },
    { title: "Units", dataIndex: "Units", key: "units" },
    { title: "Year", dataIndex: "Year", key: "year" },
    { 
      title: "List Price", 
      dataIndex: "List Price", 
      key: "listPrice",
      render: (val) => formatNumberToReadable(Number(val)),
    },
    { title: "Original/Print", 
      dataIndex: "Original/Print", 
      key: "originalPrint",
      render: (val) => val === 'O' ? <Tag color="#8884d8" >Original</Tag> : <Tag color="#ffa940" >Print</Tag>,
    },
    { 
      title: "Edition Size", 
      dataIndex: "Edition Size", 
      key: "editionSize",
      render: (val) => val ? val : 1,
    },
    { title: "Commission", 
      dataIndex: "Commission", 
      key: "commission",
      render: (val) => val ? `${val}%` : '',
    },
    { title: "For Sale", 
      dataIndex: "For Sale", 
      key: "forSale",
      render: (val) => val?.toString().toLowerCase() === 'nfs' ? <Tag color="#f5a3a3" >Not For Sale</Tag> : <Tag color="#27ae60" >For Sale</Tag>,
     },
    { title: "Public Catalog", 
      dataIndex: "Public Catalog", 
      key: "publicCatalog",
      render: (val) => val?.toString().toLowerCase() === 'public' ? <Tag color="#40a9ff" >Public</Tag> : <Tag color="#f5222d" >Private</Tag>,
     }] : []),
  ];

  const fetchKpiData = async () => {
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: '/inventoryManagementData',
        headers: {},
        data: {
          rootUser: isRootUser(),
          userId: user.id,
        },
      });
      
      setData(response);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const mapArtworkFields = (row) => ({
    title: row.Title, // Map "Title" to "title"
    description: row.Description,
    length: row.Length,
    width: row.Width,
    depth: row.Depth,
    units: row.Units,
    year: row.Year,
    price: row["List Price"],
    finalPrice: row["List Price"], // Calculate using formula
    imageUrl: row.imageUrl,
    createdByUserId: user.id,
    updatedByUserId: user.id,
    artistUsername: row.selectedArtist,
    isSaleEnabled: row["For Sale"].toLowerCase() === 'for sale' ? 1 : 0,
    copyEnabled: row["Original/Print"].toLowerCase() === 'o' ? 0 : 1,
    totalAmount: row["Edition Size"] ? row["Edition Size"] : 1,
    amountAvailable: row["Edition Size"] ? row["Edition Size"] : 1,
    commission: row.Commission,
    status: 'active',
    artistPrivate: row["Public Catalog"].toLowerCase() === 'public' ? 0 : 1,
  });

  const onboardArtwork = async (artwork, rowKey) => {
    try {
      setLoading(true);
      const transformedData = mapArtworkFields(artwork); // Map each row

      console.log("Onboardning Artowrk...", transformedData);

      setRowStatuses(prev => ({ ...prev, [rowKey]: 'pending' })); // Set to pending

      const response = await makeRequest({
        origin: window.location.origin,
        method: 'post',
        url: '/artwork',
        headers: {},
        data: {
          rootUser: isRootUser(),
          userId: user.id,
          data: transformedData,
        },
      });
      
      console.log("Onboarding status: ", response);
      
      setLoading(false);
      setRowStatuses(prev => ({ ...prev, [rowKey]: 'success' }));
    } catch (error) {
      console.error('Error fetching data:', error);
      setRowStatuses(prev => ({ ...prev, [rowKey]: 'error' }));
    }
  };

  useEffect(() => {
    fetchKpiData();
  }, []);

  const isRootUser = () => {
    return ( user && user?.id === '0' && user?.profile_name === 'Root');
  }

  const formatNumberToReadable = (num) => {
    if (num === null || num === undefined || isNaN(num)) {
      return "N/A"; // Handle invalid inputs gracefully
    }

    const number = Number(num);
    const absNumber = Math.abs(number);

    if (absNumber >= 1e9) {
      // Billions
      return `$${(number / 1e9).toFixed(2).replace(/\.00$/, "")}B`;
    } else if (absNumber >= 1e6) {
      // Millions
      return `$${(number / 1e6).toFixed(2).replace(/\.00$/, "")}M`;
    } else if (absNumber >= 1e3) {
      return `$${(number / 1e3).toFixed(2).replace(/\.00$/, "")}K`; // Thousands
    } else {
      // Less than a thousand
      return `$${number.toFixed(2).replace(/\.00$/, "")}`; // Less than a thousand
    }
  }

  return (
    data?.artists?.rows ? (
    <div>
      <Title level={5}>
        Welcome {user.display_name}!{" "}
        {data?.artists?.rows?.length > 0 && (
          <Text type="secondary" style={{ fontSize: "14px", fontWeight: "lighter" }}>
            You are representing {data.artists.rows.length} artists
          </Text>
        )}
      </Title>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} span={12}>
          <Card title="Upload Assets">
            <div style={{ display: "flex", gap: "10px"}}>
              <Upload
                beforeUpload={handleTemplateUpload}
                showUploadList={false}
              >
                <Button style={{ width: "200px" }} icon={<UploadOutlined />}>Select Template</Button>
              </Upload>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  //backgroundColor: "white", // Dark gray background
                  color: "#333",           // White text
                  //height: "40px",
                  //padding: "0 10px",        // Add padding for better spacing
                  //padding: "20px"
                }}
              >
                {isTemplateUploaded ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{templateData.length} rows found in template</span>
                  </div>
                ) : (
                  "Status: Not Uploaded"
                )}
              </div>
            </div>

            <div style={{ display: "flex", gap: "10px", marginTop: "0.5rem"}}>
              <Upload 
                directory
                showUploadList={false}
                beforeUpload={() => false}
                onChange={handleFolderSelect}
              >
                <Button style={{ width: "200px" }} icon={<UploadOutlined />}>Select Image Folder</Button>
              </Upload>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  //backgroundColor: "white", // Dark gray background
                  color: "#333",           // White text
                  //height: "40px",
                  //padding: "0 10px",        // Add padding for better spacing
                  //padding: "20px"
                }}
              >
                {isImageUploaded ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{imageList.length} images found</span>
                  </div>
                ) : (
                  "Status: Not Uploaded"
                )}
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} span={12}>
          <Card title="Batch Update">
            <div style={{ display: "flex", gap: "10px" }}>
              <Select
                placeholder={<div><UserOutlined /> <span>Select an artist</span></div>}
                style={{ width: "200px" }}
                onChange={(value) => setNewArtist(value)}
                value={newArtist || undefined}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.title.toLowerCase().includes(input.toLowerCase())
                } // Case-insensitive search
              >
                {data?.artists?.rows
                  ?.slice() // Create a shallow copy to avoid mutating the original array
                  ?.sort((a, b) => a.display_name.localeCompare(b.display_name)) // Sort alphabetically
                  ?.map((artist, index) => (
                  <Select.Option key={index} value={artist.username} title={artist.display_name}>
                    {artist.display_name}
                  </Select.Option>
                ))}
              </Select>

              <Button type="default" onClick={handleUpdateSelectedArtists} disabled={!newArtist || selectedRows.length === 0}>
                Update Selected Rows
              </Button>
            </div>
          </Card>
        </Col>
      </Row> 

      <Divider />
      
      
      <div style={{ display: "flex", gap: "10px", marginTop: "0.5rem"}}>
        <Button
          type="default"
          disabled={!isSaveEnabled || allUploadsDone}
          onClick={handleSave}//{handleOnboarding}
        >
          Save
        </Button>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //backgroundColor: "white", // Dark gray background
            color: "#333",           // White text
            //height: "40px",
            //padding: "0 10px",        // Add padding for better spacing
            //padding: "20px"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            { allUploadsDone  ? 
              /*<CheckCircleOutlined style={{ marginRight: 8, color: 'green' }} />
              <ExclamationCircleOutlined style={{ marginRight: 8, color: 'red' }} />*/
            
            <span>
              {/*successCount === 0 ? "Failed to onboard artworks." :*/
                successCount === 1 ? "1 artwork onboarded successfully!" : 
                `${successCount} artworks onboarded successfully!`}
            </span>
            : null
            }
          </div>
          
        </div>
      </div>
      
      <div style={{ overflowY: "auto", marginTop: "1rem"}}>
        <Table 
          columns={columns} 
          dataSource={templateData} 
          rowKey="key" 
          scroll={{ x: "max-content" }} 
          pagination={{ pageSize: 100 }}
        />
      </div>
      
    </div>
    ) : <Skeleton />
  )
}

export default InventoryManagement;
