
import { useEffect, useState } from 'react'
import { makeRequest } from '../../helpers/axios-manipulator';
import { Button, Card, Col, Empty, Row, Skeleton, Tag, Tooltip, message, Segmented, Input, Switch, Typography, Modal, Select } from 'antd';
import { SendOutlined, ReloadOutlined } from '@ant-design/icons';
import React from 'react';
import { OnboardingUsers } from '../../../data/models/onboarding_users';
import * as OnboardingStyles from './styles';
import emailjs from 'emailjs-com';
import UserSelect from '../../components/menu/Form/select/UserSelect';
import { User } from '../../../data/models/user';

const { Text } = Typography;

const EMAILJS_SERVICE_ID = "service_bohk2ny";
const EMAILJS_ONBOARDING_TEMPLATE_ID = "template_d7uw94h";
const EMAILJS_PUBLIC_KEY = 'zqjDUmERbRJjHE37w';

const DOMAIN_SEGMENT_OPTIONS = [
  {
    label: (
      <div style={{ padding: 0 }}>
        <p>DEV</p>
      </div>
    ),
    value: 'https://dev.ujamaa.digital/',
  },
  {
    label: (
      <div style={{ padding: 0 }}>
        <p style={{ color: "goldenrod" }}>QA</p>
      </div>
    ),
    value: 'https://qa.ujamaa.digital/',
  },
  {
    label: (
      <div style={{ padding: 0 }}>
        <p style={{ color: "purple" }}>PROD</p>
      </div>
    ),
    value: 'https://app.ujamaa.digital/',
  },
];

const DOMAIN_LIST = {
  "http://localhost:8080": "https://dev.ujamaa.digital/",
  "https://bizdev.ujamaa.digital": "https://dev.ujamaa.digital/",
  "https://bizqa.ujamaa.digital": "https://qa.ujamaa.digital/",
  "https://biz.ujamaa.digital": "https://app.ujamaa.digital/",
}

const Onboarding = () => {
  const [users, setUsers] = useState<OnboardingUsers[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showOnboarded, setShowOnboarded] = useState<boolean>(false);
  const [emailCC, setEmailCC] = useState(''); // State to store the input value
  const [emailBCC, setEmailBCC] = useState(''); // State to store the input value
  const [userData, setUserData] = useState<User>({} as User);
  const [openNewInvite, setOpenNewInvite] = useState<boolean>(false);

  // Handler to capture user input
  const handleCCInputChange = (e) => {
    setEmailCC(e.target.value);
  };

  const handleBCCInputChange = (e) => {
    setEmailBCC(e.target.value);
  };
  console.log(window.location.origin)
  console.log(DOMAIN_LIST[window.location.origin])
  const fetchOnboardingUsers = async () => {
    try {
      const response = await makeRequest({
        origin: window.location.origin,
        method: 'get',
        url: '/api-onboarding',
        headers: {},
      });

      const serverUsers = response; // Assuming response.data is the array of rows from the server

      // This was for testing...
      /*
      const numberOfUsers = serverUsers.length;

      
      // Calculate how many more rows are needed to reach 10
      const additionalUsersNeeded = Math.max(0, 10 - numberOfUsers);

      // Create additional rows if needed
      const additionalUsers = Array(additionalUsersNeeded)
        .fill(serverUsers[0]) // Use the first user as the base
        .map((user, index) => ({
          ...user, // Spread the original user properties
          notification_email: `simulated${index + 1}@example.com`, // Assign unique email addresses
        }));

      // Combine the server users with the additional simulated users
      const allUsers = [...serverUsers, ...additionalUsers];
      */
      const allUsers = [...serverUsers];

      setUsers(allUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchOnboardingUsers();
  }, []);

  const sendNotificationEmail = (e, data) => {
    if (e)
      e.preventDefault(); // Prevent default form behavior
    message.loading(`Sending an email to notify user ${data.username}`);

    // Generate the Weblink using data from the passed row
    const { id, username, notification_email } = data;

    // Dynamically generate the link
    const weblink = `${DOMAIN_LIST[window.location.origin]}${username}?onboardingId=${id}&notificationEmail=${notification_email}`;

    emailjs
      .send(
        EMAILJS_SERVICE_ID,
        EMAILJS_ONBOARDING_TEMPLATE_ID,
        {
          send_to: notification_email,
          Weblink: weblink, // Pass the dynamic Weblink,
          send_cc: emailCC,
          send_bcc: emailBCC,
        },
        EMAILJS_PUBLIC_KEY,
      ).then(
        async (result) => {
          message.success('Email sent successfully!');
          setOpenNewInvite(false);
          setUserData({} as User)
          // Since Email was sent successfully, update the status in DB
          const response = await makeRequest({
            origin: window.location.origin,
            method: 'post',
            url: '/api-onboarding',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              id: id, // Replace with the actual user ID
              notification_status: 'SENT', // Replace with the desired status
            },
          });

          if (response && response.success) {
            fetchOnboardingUsers();
          }
        },
        (error) => {
          console.error('Error sending email:', error.text);
          message.error('Failed to send email.');
        }
      );
  }

  const refreshData = async () => {
    setLoading(true);
    await fetchOnboardingUsers();
    setLoading(false);
  }
  return (
    <>
      <Button type="primary" style={{ float: "right", marginTop: "-2.5rem", padding: ".5rem 1.5rem", height: "auto" }}
        onClick={() => setOpenNewInvite(true)}
      >Re-invite</Button>
      <div className='mt-5' style={{ height: '100%' }}>
        <Card
          title={`Onboard Users | ${users.filter(item => item.notification_status === 'OPEN').length} Not Notified`}
          style={{ backgroundColor: "#e0e0e0" }}
          extra={<Tooltip title="Refresh">
            <Button shape='circle' style={{ backgroundColor: "#f0f0f0" }} onClick={refreshData}>
              <ReloadOutlined style={{ color: "blue" }} />
            </Button>
          </Tooltip>}
        >
          <Row gutter={8}>
            <Col span={4}>
              {/* <Segmented
              onChange={onDomainChanged}
              options={DOMAIN_SEGMENT_OPTIONS}
            /> */}
              <p className='mb-0' style={{ fontSize: "12px" }}>Domain:
              </p>
              <b>{DOMAIN_LIST[window.location.origin]}</b>
            </Col>
            <Col span={24} md={10}>
              <div>
                <span>CC:</span>
                <Input allowClear onChange={handleCCInputChange} />
              </div>
            </Col>
            <Col span={24} md={10}>
              <div>
                <span>BCC:</span>
                <Input allowClear onChange={handleBCCInputChange} />
              </div>
            </Col>
          </Row>

          <div style={{ height: '0.5rem' }} />

          <hr />
          <p className='mb-0'>
            <Text>Show onboarded</Text>
          </p>
          <Switch className='mt-0 mb-3' defaultChecked={showOnboarded} onChange={() => setShowOnboarded(!showOnboarded)} />



          <Row gutter={[16, 16]} justify={`${!loading && users?.length === 0 ? "center" : "start"}`} align={`${!loading && users?.length === 0 ? "middle" : "top"}`} style={{ height: '100%', alignContent: 'flex-start' }}>
            {loading ?
              <>
                <Col span={24} md={12} lg={8}>
                  <Skeleton.Image className='mobile-skeleton-image' active={true} style={{ width: 260, height: 250 }} />
                </Col>
                <Col span={24} md={12} lg={8}>
                  <Skeleton.Image className='mobile-skeleton-image' active={true} style={{ width: 260, height: 250 }} />
                </Col>
                <Col span={24} md={12} lg={8}>
                  <Skeleton.Image className='mobile-skeleton-image' active={true} style={{ width: 260, height: 250 }} />
                </Col>
              </> :
              <>
                {users.length > 0 ? users.slice() // Create a shallow copy to avoid mutating the original array
                  .sort((a, b) => {
                    const nameA = a.username || ""; // Fallback to an empty string if null or undefined
                    const nameB = b.username || ""; // Fallback to an empty string if null or undefined
                    return nameA.localeCompare(nameB);
                  }) // Sort alphabetically by username
                  .map((user, index) => (
                    <>
                      {!showOnboarded && user.notification_status === 'COMPLETE' ? <></> :
                        <Col key={index} span={24} md={12} lg={8}>
                          <Card
                            title={<OnboardingStyles.CardHeaderContainer><span>{user.username}</span><span>{user.notification_status === 'OPEN' ? (
                              <Tag color='orange'>Incomplete</Tag>
                            ) : user.notification_status === 'SENT' ? (
                              <Tag color='green'>Email Sent</Tag>
                            ) : (
                              <Tag color='purple'>ONBOARDED</Tag>
                            )}</span></OnboardingStyles.CardHeaderContainer>}
                            bordered={false}
                            //style={{ cursor: 'pointer' }} 
                            extra={user.is_active ?
                              <Tooltip title="Send Onboarding Email">
                                <Button
                                  onClick={(e) => sendNotificationEmail(e, user)}
                                >
                                  <SendOutlined />
                                </Button>
                              </Tooltip> : null}
                          >
                            <p>User Id: <OnboardingStyles.BoldText>{user.userid}</OnboardingStyles.BoldText></p>
                            <p>Type: {user.account_type}</p>
                            <p>Email: <OnboardingStyles.BlueText>{user.notification_email}</OnboardingStyles.BlueText></p>
                          </Card>
                        </Col>
                      }
                    </>
                  )) :
                  <Empty />}
              </>
            }
          </Row>
        </Card>
        {openNewInvite &&
          <Modal
            centered
            title="Send invitation"
            open={openNewInvite}
            okText="send"
            onOk={() => sendNotificationEmail(null, userData)}
            okButtonProps={{ disabled: userData && Object.keys(userData)?.length === 0 }}
            onCancel={() => { setOpenNewInvite(false); setUserData({} as User) }}
            width={375}
          >
            <UserSelect route='/api-onboarding/availables' update={loading} className='mb-5' setData={setUserData} data={userData} />
          </Modal>
        }
      </div>
    </>
  )
}

export default Onboarding;

