import React from 'react';
import { Col, Row, Statistic, Space } from 'antd';
import { ResponsiveContainer } from 'recharts';
import LabelValue from '../LabelValue/LabelValue';
import { Typography } from 'antd';

const { Title } = Typography;

const KpiCard = (props: any) => {
  const columnCount = Math.min(props.labels.length, 4); // Limit to max 4 columns
  const colSpan = columnCount > 0 ? 24 / columnCount : 6; // Calculate span based on number of items

  return (
    <div style={{ display: "block", 
                  height: props?.height ? props.height : "100%",
                  minWidth: "150px",
                  minHeight: "100px",
                  backgroundColor: "rgba(255, 255, 255, 1)", 
                  border: "2px solid #f0f0f0", 
                  borderRadius: "5px", 
                  paddingTop: "1rem", 
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  paddingBottom: props.chart ? "0px" : "1rem",
                  alignItems: "center", 
                  justifyContent: "center",
                  position: "relative",
                  width: "100%",
                  
                }}>
      <Row>
        <Col span={22}>
        <Row gutter={8}>
      {props.labels.map((label, index) => (
        <Col key={index} span={colSpan}>
          <Space
            direction="vertical"
            size={0}
            style={{
              height: "100%",
              justifyContent: "center"
            }}
          >
            {index === 0 ? (
              
              <LabelValue label={label || null} value={props.values?.[index] || null} secondaryValue={props.secondaryValues?.[index] || null} position="top" size="large"/>
            ) : (
              <LabelValue label={label || null} value={props.values?.[index] || null} secondaryValue={props.secondaryValues?.[index] || null} position="top" />
            )}
          </Space>
        </Col>
      ))}
    </Row>


        
        </Col>
          
        <Col span={2}>
          <Space
            direction="vertical"
            size={0}
            style={{
              height: "100%",
              //justifyContent: "center",
              
            }}
          >
            {props.icon ? props.icon : null}
          </Space>
          </Col>
        </Row>

        {props.chart ? (
        <Row style={{ width: "100%" }}>
          <Col style={{ width: "100%" }}>
            {React.cloneElement(props.chart, { width: "100%" })} {/* Ensure chart receives correct width */}
          </Col>
        </Row>
       
      ) : null}

      
        </div>
  );
};

export default KpiCard;
