import { QRCode } from 'antd'
import React from 'react'
import img from '../../assets/icons/logo-icon-white-1xl.png'
import * as S from './styles'
import { ScanOutlined } from '@ant-design/icons'
import logo from '../../assets/images/black-logo.png'

const Placard = ({ data }) => {
    return (
        <div>
            <S.Container>
                <S.Header>
                    <ScanOutlined style={{ fontSize: '77px' }} />
                    <section>
                        <p>scan to view &</p>
                        <p>pay by mobile</p>
                    </section>
                </S.Header>
                <S.NumberContainer>
                    <p className='first'>PLACARD NO.</p>
                    <p className='second'>{data.code}</p>
                </S.NumberContainer>
                <S.QRCondeContainer>
                    <QRCode
                        errorLevel="H"
                        value={`${process.env.REACT_APP_ENV === 'development' ? 'https://dev.ujamaa.digital/artwork' : process.env.REACT_APP_ENV === 'production' ? 'https://app.ujamaa.digital/artwork' : 'https://qa.ujamaa.digital/artwork'}/${data.code}`}
                        size={524.79}
                        iconSize={524.79 / 4}
                        icon={img}
                        bordered={false}
                    />
                </S.QRCondeContainer>
                <S.PoweredBy>
                    Powered by
                </S.PoweredBy>
                <S.FooterContainer>
                    <img style={{ maxWidth: '330.4px', maxHeight: '84px' }} src={logo} alt={'Black ujamaa logo'} />
                </S.FooterContainer>
            </S.Container>
        </div>
    )
}

export default Placard
