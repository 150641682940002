import axios, { AxiosRequestConfig } from "axios";

interface RequestParams {
  origin: string,
  method: 'get' | 'post' | 'put' | 'delete';
  url: string;
  data?: any;
  headers?: Record<string, string>;
}

export async function makeRequest({ origin, method, url, data, headers }: RequestParams): Promise<any> {
  //const newUrl = (process.env.REACT_APP_ENV === 'test' ? 'http://localhost:3000':process.env.REACT_APP_DOMAIN) + url;
  const newUrl = (origin.includes('localhost') ? 'http://localhost:3000' + url : origin + url);
  console.log('>>>>>>>>>', origin, newUrl)
  const config: AxiosRequestConfig = {
    method,
    url: newUrl,
    headers,
  };

  if (data) {
    if (method.toLowerCase() === 'get') {
      // For GET requests, use `params` instead of `data`
      config.params = data;
    } else {
      // For other methods, use `data`
      config.data = data;
    }
  }

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    // Handle error as needed
    console.error('Error making request:', error);
    throw error;
  }
}